<!--begin::Root-->
<div class="d-flex flex-column flex-root">
  <!--begin::Page-->
  <div class="page d-flex flex-column flex-column-fluid">
    <app-header
      id="kt_header"
      class="header align-items-stretch"
      [ngClass]="headerCSSClasses"
      #ktHeader
      style="
        z-index: 1000;
        position: fixed;
        bottom: 0;
        right: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 1000px;
      "
    >
    </app-header>
    <!--begin::Container-->
    <div
      id="kt_content_container"
      class="d-flex flex-column-fluid align-items-stretch"
      style="width: 100%; max-width: 1000px"
      [ngClass]="contentContainerClasses"
    >
      <ng-container *ngIf="asideDisplay">
        <ng-container *ngIf="headerFixedDesktop">
          <app-aside
            id="kt_aside"
            class="aside"
            data-kt-drawer="true"
            data-kt-drawer-name="aside"
            data-kt-drawer-activate="{default: true, lg: false}"
            data-kt-drawer-overlay="true"
            data-kt-drawer-width="{default:'200px', '300px': '225px'}"
            data-kt-drawer-direction="start"
            data-kt-drawer-toggle="#kt_aside_toggle"
            data-kt-sticky="true"
            data-kt-sticky-name="aside-sticky"
            data-kt-sticky-offset="{default: false, lg: '1px'}"
            data-kt-sticky-width="{lg: '225px'}"
            data-kt-sticky-left="auto"
            data-kt-sticky-top="94px"
            data-kt-sticky-animation="false"
            data-kt-sticky-zindex="95"
          >
          </app-aside>
        </ng-container>
        <ng-container *ngIf="!headerFixedDesktop">
          <app-aside
            id="kt_aside"
            class="aside"
            data-kt-drawer="true"
            data-kt-drawer-name="aside"
            data-kt-drawer-activate="{default: true, lg: false}"
            data-kt-drawer-overlay="true"
            data-kt-drawer-width="{default:'200px', '300px': '225px'}"
            data-kt-drawer-direction="start"
            data-kt-drawer-toggle="#kt_aside_toggle"
          ></app-aside>
        </ng-container>
      </ng-container>
      <!--begin::Wrapper-->
      <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
        <!--begin::Content-->
        <div id="kt_content" class="content flex-column-fluid">
          <ng-container *ngIf="title$ | async">
            <ng-container *ngIf="toolbarDisplay">
              <app-toolbar></app-toolbar>
            </ng-container>
          </ng-container>

          <!--begin::Post-->
          <div class="post" id="kt_post">
            <app-content id="kt_content_container" [ngClass]=""></app-content>
          </div>
          <!--end::Post-->
        </div>
        <!--end::Content -->
        <app-footer
          style="
            z-index: 9999;
            position: fixed;
            bottom: 0;
            right: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            max-width: 1000px;
          "
          class="d-flex flex-lg-column"
          [ngClass]="footerCSSClasses"
          id="kt_footer"
        ></app-footer>
      </div>
      <!--end::Wrapper-->
      <ng-container *ngIf="showSidebar$ | async">
        <app-sidebar
          class="sidebar p-5 px-lg-0 py-lg-11"
          data-kt-drawer="true"
          data-kt-drawer-name="sidebar"
          data-kt-drawer-activate="{default: true, lg: false}"
          data-kt-drawer-overlay="true"
          data-kt-drawer-width="275px"
          data-kt-drawer-direction="end"
          data-kt-drawer-toggle="#kt_sidebar_toggle"
        >
        </app-sidebar>
      </ng-container>
    </div>
    <!--end::Container-->
  </div>
  <!--end::Page-->
</div>
<!--end::Root-->

<app-scripts-init></app-scripts-init>
<!-- <app-scroll-top></app-scroll-top> -->

<!-- begin:: Drawers -->
<app-activity-drawer></app-activity-drawer>
<app-messenger-drawer></app-messenger-drawer>
<!-- end:: Drawers -->

<!-- end:: Engage -->
<!-- <app-engages></app-engages> -->
<!-- end:: Engage -->

<!-- begin:: Modals -->
<!-- <app-main-modal></app-main-modal> -->
<!-- <app-invite-users-modal></app-invite-users-modal> -->
<!-- <app-upgrade-plan-modal></app-upgrade-plan-modal> -->
<!-- end:: Modals -->
