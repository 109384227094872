<div [ngClass]="footerContainerCssClasses" class="w-full h-16 border-t border-F6A32F bg-black">
  <div>
    <nav>
      <ul class="flex flex-row justify-around py-1 text-06rem font-semibold">
        <li routerLink="/main" (click)="tab = 1">
          <span class="Footer_link__LnE0_"><span style="
                box-sizing: border-box;
                display: inline-block;
                overflow: hidden;
                width: initial;
                height: initial;
                background: none;
                opacity: 1;
                border: 0px;
                margin: 0px;
                padding: 0px;
                position: relative;
                max-width: 100%;
              "><span style="
                  box-sizing: border-box;
                  display: block;
                  width: initial;
                  height: initial;
                  background: none;
                  opacity: 1;
                  border: 0px;
                  margin: 0px;
                  padding: 0px;
                  max-width: 100%;
                "><img alt="" aria-hidden="true"
                  src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2730%27%20height=%2730%27/%3e"
                  style="
                    display: block;
                    max-width: 100%;
                    width: initial;
                    height: initial;
                    background: none;
                    opacity: 1;
                    border: 0px;
                    margin: 0px;
                    padding: 0px;
                  " /></span><img *ngIf="tab === 1" src="./../../../../assets/images/menubar_mainpage2.png"
                decoding="async" data-nimg="intrinsic" style="
                  position: absolute;
                  inset: 0px;
                  box-sizing: border-box;
                  padding: 0px;
                  border: none;
                  margin: auto;
                  display: block;
                  width: 20px;
                  height: 20px;
                  min-width: 100%;
                  max-width: 100%;
                  min-height: 100%;
                  max-height: 100%;
                " />
              <img *ngIf="tab !== 1" src="./../../../../assets/images/menubar_mainpage2.png" decoding="async"
                data-nimg="intrinsic" style="
                  position: absolute;
                  inset: 0px;
                  box-sizing: border-box;
                  padding: 0px;
                  border: none;
                  margin: auto;
                  display: block;
                  width: 20px;
                  height: 20px;
                  min-width: 100%;
                  max-width: 100%;
                  min-height: 100%;
                  max-height: 100%;
                " /><noscript></noscript></span><span>หน้าแรก</span></span>
        </li>
        <li routerLink="/history" (click)="tab = 2">
          <span class="Footer_link__LnE0_"><span style="
                box-sizing: border-box;
                display: inline-block;
                overflow: hidden;
                width: initial;
                height: initial;
                background: none;
                opacity: 1;
                border: 0px;
                margin: 0px;
                padding: 0px;
                position: relative;
                max-width: 100%;
              "><span style="
                  box-sizing: border-box;
                  display: block;
                  width: initial;
                  height: initial;
                  background: none;
                  opacity: 1;
                  border: 0px;
                  margin: 0px;
                  padding: 0px;
                  max-width: 100%;
                "><img alt="" aria-hidden="true"
                  src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2730%27%20height=%2730%27/%3e"
                  style="
                    display: block;
                    max-width: 100%;
                    width: initial;
                    height: initial;
                    background: none;
                    opacity: 1;
                    border: 0px;
                    margin: 0px;
                    padding: 0px;
                  " /></span><img *ngIf="tab === 2" src="./../../../../assets/images/transaction history_c.png"
                decoding="async" data-nimg="intrinsic" style="
                  position: absolute;
                  inset: 0px;
                  box-sizing: border-box;
                  padding: 0px;
                  border: none;
                  margin: auto;
                  display: block;
                  width: 0px;
                  height: 0px;
                  min-width: 100%;
                  max-width: 100%;
                  min-height: 100%;
                  max-height: 100%;
                " />
              <img *ngIf="tab !== 2" src="./../../../../assets/images/transaction history_c.png" decoding="async"
                data-nimg="intrinsic" style="
                  position: absolute;
                  inset: 0px;
                  box-sizing: border-box;
                  padding: 0px;
                  border: none;
                  margin: auto;
                  display: block;
                  width: 0px;
                  height: 0px;
                  min-width: 100%;
                  max-width: 100%;
                  min-height: 100%;
                  max-height: 100%;
                " /><noscript></noscript></span><span>รายงานเงิน</span></span>
        </li>

        <li routerLink="/games/recommend" (click)="tab = 3">
          <span class="Footer_link__LnE0_"><span style="
                box-sizing: border-box;
                display: inline-block;
                overflow: hidden;
                width: initial;
                height: initial;
                background: none;
                opacity: 1;
                border: 0px;
                margin: 0px;
                padding: 0px;
                position: relative;
                max-width: 100%;
              "><span style="
                  box-sizing: border-box;
                  display: block;
                  width: initial;
                  height: initial;
                  background: none;
                  opacity: 1;
                  border: 0px;
                  margin: 0px;
                  padding: 0px;
                  max-width: 100%;
                "><img alt="" aria-hidden="true"
                  src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2730%27%20height=%2730%27/%3e"
                  style="
                    display: block;
                    max-width: 100%;
                    width: initial;
                    height: initial;
                    background: none;
                    opacity: 1;
                    border: 0px;
                    margin: 0px;
                    padding: 0px;
                  " /></span><img *ngIf="tab === 3" src="./../../../../assets/images/slot.png" decoding="async"
                data-nimg="intrinsic" style="
                  position: absolute;
                  inset: 0px;
                  box-sizing: border-box;
                  padding: 0px;
                  border: none;
                  margin: auto;
                  display: block;
                  width: 0px;
                  height: 0px;
                  min-width: 100%;
                  max-width: 100%;
                  min-height: 100%;
                  max-height: 100%;
                " />
              <img *ngIf="tab !== 3" src="./../../../../assets/images/slot.png" decoding="async" data-nimg="intrinsic"
                style="
                  position: absolute;
                  inset: 0px;
                  box-sizing: border-box;
                  padding: 0px;
                  border: none;
                  margin: auto;
                  display: block;
                  width: 0px;
                  height: 0px;
                  min-width: 100%;
                  max-width: 100%;
                  min-height: 100%;
                  max-height: 100%;
                " /><noscript></noscript></span><span>เข้าเกมส์</span></span>
        </li>
        <li routerLink="/member/affiliate" (click)="tab = 5">
          <span class="Footer_link__LnE0_"><span style="
                box-sizing: border-box;
                display: inline-block;
                overflow: hidden;
                width: initial;
                height: initial;
                background: none;
                opacity: 1;
                border: 0px;
                margin: 0px;
                padding: 0px;
                position: relative;
                max-width: 100%;
              "><span style="
                  box-sizing: border-box;
                  display: block;
                  width: initial;
                  height: initial;
                  background: none;
                  opacity: 1;
                  border: 0px;
                  margin: 0px;
                  padding: 0px;
                  max-width: 100%;
                ">
                <img alt="" aria-hidden="true"
                  src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2730%27%20height=%2730%27/%3e"
                  style="
                    display: block;
                    max-width: 100%;
                    width: initial;
                    height: initial;
                    background: none;
                    opacity: 1;
                    border: 0px;
                    margin: 0px;
                    padding: 0px;
                  " /></span>

              <img src="./../../../../assets/images/affiliate_team.png" decoding="async" data-nimg="intrinsic" style="
                  position: absolute;
                  inset: 0px;
                  box-sizing: border-box;
                  padding: 0px;
                  border: none;
                  margin: auto;
                  display: block;
                  width: 0px;
                  height: 0px;
                  min-width: 100%;
                  max-width: 100%;
                  min-height: 100%;
                  max-height: 100%;
                " />
              <noscript></noscript></span><span>แนะนำ</span></span>
        </li>
        <!-- <li routerLink="/member/affiliate" (click)="tab = 4">
          <span class="Footer_link__LnE0_"
            ><span
              style="
                box-sizing: border-box;
                display: inline-block;
                overflow: hidden;
                width: initial;
                height: initial;
                background: none;
                opacity: 1;
                border: 0px;
                margin: 0px;
                padding: 0px;
                position: relative;
                max-width: 100%;
              "
              ><span
                style="
                  box-sizing: border-box;
                  display: block;
                  width: initial;
                  height: initial;
                  background: none;
                  opacity: 1;
                  border: 0px;
                  margin: 0px;
                  padding: 0px;
                  max-width: 100%;
                "
                ><img
                  alt=""
                  aria-hidden="true"
                  src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2730%27%20height=%2730%27/%3e"
                  style="
                    display: block;
                    max-width: 100%;
                    width: initial;
                    height: initial;
                    background: none;
                    opacity: 1;
                    border: 0px;
                    margin: 0px;
                    padding: 0px;
                  " /></span
              ><img
                *ngIf="tab === 4"
                src="./../../../../assets/images/menubar_team2.png"
                decoding="async"
                data-nimg="intrinsic"
                style="
                  position: absolute;
                  inset: 0px;
                  box-sizing: border-box;
                  padding: 0px;
                  border: none;
                  margin: auto;
                  display: block;
                  width: 0px;
                  height: 0px;
                  min-width: 100%;
                  max-width: 100%;
                  min-height: 100%;
                  max-height: 100%;
                " />
              <img
                *ngIf="tab !== 4"
                src="./../../../../assets/images/menubar_team.png"
                decoding="async"
                data-nimg="intrinsic"
                style="
                  position: absolute;
                  inset: 0px;
                  box-sizing: border-box;
                  padding: 0px;
                  border: none;
                  margin: auto;
                  display: block;
                  width: 0px;
                  height: 0px;
                  min-width: 100%;
                  max-width: 100%;
                  min-height: 100%;
                  max-height: 100%;
                " /><noscript></noscript></span
            ><span>แนะนำเพื่อน</span></span
          >
        </li> -->
        <li routerLink="/member/info" (click)="tab = 5">
          <span class="Footer_link__LnE0_"><span style="
                box-sizing: border-box;
                display: inline-block;
                overflow: hidden;
                width: initial;
                height: initial;
                background: none;
                opacity: 1;
                border: 0px;
                margin: 0px;
                padding: 0px;
                position: relative;
                max-width: 100%;
              "><span style="
                  box-sizing: border-box;
                  display: block;
                  width: initial;
                  height: initial;
                  background: none;
                  opacity: 1;
                  border: 0px;
                  margin: 0px;
                  padding: 0px;
                  max-width: 100%;
                "><img alt="" aria-hidden="true"
                  src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2730%27%20height=%2730%27/%3e"
                  style="
                    display: block;
                    max-width: 100%;
                    width: initial;
                    height: initial;
                    background: none;
                    opacity: 1;
                    border: 0px;
                    margin: 0px;
                    padding: 0px;
                  " /></span><img *ngIf="tab === 5" src="./../../../../assets/images/menubar_profile2.png"
                decoding="async" data-nimg="intrinsic" style="
                  position: absolute;
                  inset: 0px;
                  box-sizing: border-box;
                  padding: 0px;
                  border: none;
                  margin: auto;
                  display: block;
                  width: 0px;
                  height: 0px;
                  min-width: 100%;
                  max-width: 100%;
                  min-height: 100%;
                  max-height: 100%;
                " />
              <img *ngIf="tab !== 5" src="./../../../../assets/images/menubar_profile2.png" decoding="async"
                data-nimg="intrinsic" style="
                  position: absolute;
                  inset: 0px;
                  box-sizing: border-box;
                  padding: 0px;
                  border: none;
                  margin: auto;
                  display: block;
                  width: 0px;
                  height: 0px;
                  min-width: 100%;
                  max-width: 100%;
                  min-height: 100%;
                  max-height: 100%;
                " /><noscript></noscript></span><span>โปรไฟล์</span></span>
        </li>
      </ul>
    </nav>
  </div>
</div>