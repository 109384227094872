<div class="me-n5 pe-1" style="height: 60vh">
  <cdk-virtual-scroll-viewport itemSize="100" class="example-viewport2 app-chat-msg" #virtualScroll>
    <ng-container *ngFor="let message of messagesObs">
      <div [ngClass]="{
          'd-flex': !isDrawer,
          'd-none': message.template,
          'justify-content-start': userData.id !== message.sender_id,
          'justify-content-end': userData.id === message.sender_id
        }" [attr.data-kt-element]="'template-' + message.type" class="mb-10 px-2">
        <div class="d-flex flex-column" [ngClass]="
            userData.id !== message.sender_id
              ? 'align-items-start'
              : 'align-items-end'
          ">
          <!-- <div
      class="d-flex flex-column align-items"
      [ngClass]="'align-items-' + (message.type === 'in' ? 'start' : 'end')"
    > -->
          <div class="d-flex align-items-center mb-2">
            <ng-container *ngIf="userData.id !== message.sender_id">
              <div div class="symbol symbol-35px symbol-circle">
                <img alt="Pic" [src]="'./assets/img/admin.png'" />
              </div>
              <div class="ms-3">
                <a href="#" class="fs-5 fw-bolder text-gray-900 text-hover-primary me-1">
                  แอดมิน
                </a>
                <span class="text-muted fs-7 mb-1">{{
                  message.create_date.toDate() | thaidate : "medium"
                  }}</span>
              </div>
            </ng-container>
            <ng-container *ngIf="userData.id === message.sender_id">
              <div class="me-3">
                <span class="text-muted fs-7 mb-1">{{
                  message.create_date.toDate() | thaidate : "medium"
                  }}</span>
                <a href="#" class="fs-5 fw-bolder text-gray-900 text-hover-primary ms-1">
                  {{userData.username}}
                </a>
              </div>
              <div class="symbol symbol-35px symbol-circle">
                <img alt="Pic" [src]="'./assets/img/user.png'" />
              </div>
            </ng-container>
          </div>

          <div *ngIf="message.type === 'message'" [ngClass]="getMessageCssClass(message)" data-kt-element="message-text"
            [innerHTML]="message.text"></div>
          <div *ngIf="message.type === 'image'" [ngClass]="getMessageCssClass(message)" data-kt-element="message-text">
            <img [src]="message.text" width="150" alt="" />
          </div>
        </div>
      </div>
    </ng-container></cdk-virtual-scroll-viewport>
</div>

<div class="card-footer pt-4 p-0" [id]="
    isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'
  ">
  <textarea #messageInput class="form-control form-control-flush mb-3" rows="1" data-kt-element="input"
    placeholder="พิมพ์ข้อความที่นี่"></textarea>

  <div class="d-flex flex-stack">
    <div class="d-flex align-items-center me-2">
      <button class="btn btn-sm btn-icon btn-active-light-primary me-1" type="button" (click)="file.click()">
        <i class="bi bi-paperclip" style="font-size: 20px !important"></i>
      </button>
      <input type="file" id="img" style="display: none" #file (change)="selectFile($event)" />
      <!-- <button
        class="btn btn-sm btn-icon btn-active-light-primary me-1"
        type="button"
        ngbTooltip="Coming soon"
      >
        <i class="bi bi-upload fs-3"></i>
      </button> -->
    </div>
    <button class="btn btn-primary" type="button" data-kt-element="send" (click)="submitMessage()">
      ส่งข้อความ
    </button>
  </div>
</div>