import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Observable, finalize } from 'rxjs';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  defaultMessages,
  defaultUserInfos,
  messageFromClient,
  MessageModel,
  UserInfoModel,
} from './dataExample';
import * as crypto from 'crypto-js';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
export class FileUpload {
  key: any;
  name: any;
  url: any;
  file: File;

  constructor(file: File) {
    this.file = file;
  }
}
@Component({
  selector: 'app-chat-inner',
  templateUrl: './chat-inner.component.html',
  styleUrls: ['./chat-inner.component.scss'],
})

export class ChatInnerComponent implements OnInit {
  @Input() isDrawer: boolean = false;
  @HostBinding('class') class = 'card-body';
  @HostBinding('id') id = this.isDrawer
    ? 'kt_drawer_chat_messenger_body'
    : 'kt_chat_messenger_body';
  @ViewChild('messageInput', { static: true })
  messageInput: ElementRef<HTMLTextAreaElement>;

  @ViewChild("virtualScroll", { static: true })
  public virtualScroll: CdkVirtualScrollViewport;

  private basePath = '/uploads';
  selectedFiles?: FileList;
  currentFileUpload?: FileUpload;



  private messages$: BehaviorSubject<Array<MessageModel>> = new BehaviorSubject<
    Array<MessageModel>
  >(defaultMessages);
  messagesObs: any;

  constructor(private db: AngularFirestore, private storage: AngularFireStorage, private appRef: ChangeDetectorRef) {

    // this.messagesObs = this.messages$.asObservable();
  }

  submitMessage(): void {
    const text = this.messageInput.nativeElement.value;
    const newMessage: MessageModel = {
      user: 2,
      type: 'out',
      text,
      time: 'Just now',
    };
    this.addMessage(this.messageInput.nativeElement.value);
    // auto answer
    // setTimeout(() => {
    //   this.addMessage(messageFromClient);
    // }, 4000);
    // clear input
    this.messageInput.nativeElement.value = '';
  }

  addMessage(newMessage: any): void {
    // // const messages = [...this.messages$.value];
    // messages.push(newMessage);
    // this.messages$.next(messages);
    if (this.messagesObs.length > 0) {
      this.db.collection('chat').doc(this.userData.id).collection('message_chat').add({
        "agentcode": this.userData.agentcode,
        "create_date": new Date(),
        "receive_id": null,
        "sender_id": this.userData.id,
        "text": newMessage,
        "type": "message"
      })
      this.db.collection('chat').doc(this.userData.id).update({
        lastmessage_date: new Date(),
        unread: true,
        last_message: newMessage
      })
    }
    else {
      this.db.collection('chat').doc(this.userData.id).set({
        "agentcode": this.userData.agentcode,
        "create_date": new Date(),
        "last_message": "",
        "lastmessage_date": new Date(),
        "room_id": this.userData.id,
        "unread": false,
        "user_id": this.userData.id,
        "username": this.userData.username
      }).then(() => {
        this.db.collection('chat').doc(this.userData.id).collection('message_chat').add({
          "agentcode": this.userData.agentcode,
          "create_date": new Date(),
          "receive_id": null,
          "sender_id": this.userData.id,
          "text": newMessage,
          "type": "message"
        })
        this.db.collection('chat').doc(this.userData.id).update({
          lastmessage_date: new Date(),
          unread: true,
          last_message: newMessage
        })
      })
    }

  }

  getUser(user: number): UserInfoModel {
    return defaultUserInfos[user];
  }
  selectFile(event: any): void {

    this.selectedFiles = event.target.files;
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);
      this.selectedFiles = undefined;
      // image/png, image/gif, image/jpeg
      if (file) {
        if (file && (file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/jpeg')) {
          this.currentFileUpload = new FileUpload(file);
          const filePath = `${this.basePath}/${this.currentFileUpload.file.name}`;
          const storageRef = this.storage.ref(filePath);
          const uploadTask = this.storage.upload(filePath, this.currentFileUpload.file);
          uploadTask.snapshotChanges().pipe(
            finalize(() => {
              storageRef.getDownloadURL().subscribe((downloadURL: any) => {
                this.db.collection('chat').doc(this.userData.id).collection('message_chat').add({
                  "agentcode": this.userData.agentcode,
                  "create_date": new Date(),
                  "receive_id": null,
                  "sender_id": this.userData.id,
                  "text": downloadURL,
                  "type": "image"
                })
                this.db.collection('chat').doc(this.userData.id).update({
                  lastmessage_date: new Date(),
                  unread: true,
                  last_message: 'ส่งรูปภาพ'
                })
                // console.log(downloadURL)

                this.appRef.detectChanges();
              });
            })
          ).subscribe();
        }
      }
    }
  }
  getMessageCssClass(message: MessageModel): string {
    return `p-5 rounded text-dark fw-bold mw-lg-400px bg-light-${message.type === 'in' ? 'info' : 'primary'
      } text-${message.type === 'in' ? 'start' : 'end'}`;
  }
  userData: any
  ngOnInit(): void {
    const key = "J16SDyGk7XR1dZt3mwhu";
    let agentdata = localStorage.getItem("nabngientoken")
    let sha = crypto.SHA256(key).toString();
    let md5 = crypto.MD5(sha).toString();
    let decData = crypto.enc.Base64.parse(agentdata).toString(
      crypto.enc.Utf8
    );
    let bytesa = crypto.AES.decrypt(decData || "", md5).toString(
      crypto.enc.Utf8
    );
    const jsonobj = JSON.parse(bytesa);
    this.userData = jsonobj[0]
    this.db.collection('chat').doc(jsonobj[0].id).collection('message_chat', ref => ref.orderBy('create_date', 'asc')).valueChanges().subscribe(res => {
      this.messagesObs = res
      this._scrollToBottom(res.length)
      this.appRef.detectChanges()
    })
  }
  private _scrollToBottom(length) {
    setTimeout(() => {
      this.virtualScroll.scrollToIndex(
        length * 2
      );
      setTimeout(() => {
        const items = document.getElementsByClassName("app-chat-msg");
        items[items.length - 1].scrollIntoView();
      }, 10);
    });
  }
}
